import { ref, reactive, computed, ComputedRef } from 'vue';
import { ROUTE_NAMES } from '@/router';
import { VALIDATION_SCHEMA } from '@/common/validation';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { Breakpoint } from '@/common/constant';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { load } from 'recaptcha-v3';
import { SignupForm, CreateAccountForm } from '@/api/openapi';
import { useSignupStore } from '@/store/signup';
import { AccountApi } from '@/api';
import { toast } from '@/components/ui/Toast';
import * as Yup from 'yup';

type signupParams = {
  email: string;
};

type accountParams = {
  firstName: string;
  lastName: string;
  password: string;
  code: string;
};

type breakPoint = {
  breakPoint: ComputedRef<'sm' | 'md' | 'xl'>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSignupData = (): any => {
  const signup = useSignupStore();
  const router = useRouter();
  const route = useRoute();

  const {
    email,
    firstName,
    lastName,
    password,
    confirmPassword
  } = VALIDATION_SCHEMA;
  const indexSchema = Yup.object().shape({ email });
  const welcomeSchema = Yup.object().shape({
    code: Yup.string().required(VALIDATION_MESSAGE.required),
    firstName,
    lastName,
    password,
    confirmPassword
  });
  const label = ref<string>('同意する');
  const isLoading = ref<boolean>(false);
  const isChecked = ref<boolean>(false);
  const isAuth = route.params.type ? route.params.type : '';

  const getQuery = reactive({
    inflowType: route.query.inflowType ? route.query.inflowType : 'WEB',
    advertiseCode: route.query.advertiseCode ? route.query.advertiseCode : null,
    invitationWorkspaceId: route.query.invitationWorkspaceId
      ? Number(route.query.invitationWorkspaceId)
      : null,
    invitatorId: route.query.invitatorId
      ? Number(route.query.invitatorId)
      : null
  });

  const signupParams = async (value: signupParams) => {
    const { getToken } = useRecaptcha();
    const token = await getToken();
    const params = {
      email: value.email,
      inflowType: getQuery.inflowType,
      reCaptchaToken: token
    } as SignupForm;

    if (getQuery.advertiseCode) {
      params.advertiseCode = getQuery.advertiseCode as string;
    }
    if (getQuery.invitationWorkspaceId) {
      params.invitationWorkspaceId = getQuery.invitationWorkspaceId;
    }
    if (getQuery.invitatorId) {
      params.invitatorId = getQuery.invitatorId;
    }

    // store保存
    signup.setParams(params);

    return params;
  };

  const accountParams = (value: accountParams) => {
    const params = {
      email: signup.email,
      firstName: value.firstName,
      lastName: value.lastName,
      password: value.password,
      activationCode: value.code
    } as CreateAccountForm;

    return params;
  };

  return {
    router,
    indexSchema,
    welcomeSchema,
    label,
    isLoading,
    isChecked,
    isAuth,
    signupParams,
    accountParams
  };
};

export const useBreakPoint = (): breakPoint => {
  const innerWidth = ref(window.innerWidth);
  const breakPoint = computed(() => {
    if (innerWidth.value < Breakpoint.md) {
      return 'sm';
    } else if (innerWidth.value >= Breakpoint.md) {
      return 'md';
    } else {
      return 'xl';
    }
  });

  return {
    breakPoint
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRecaptcha = (): any => {
  const recaptchaInstance = ref();

  const showBadge = () => {
    load(process.env.VUE_APP_RECAPTCHA_SITE_KEY as string, {
      autoHideBadge: false
    }).then(recaptcha => {
      recaptchaInstance.value = recaptcha;
      recaptchaInstance.value.showBadge();
    });
  };

  const hideBadge = () => {
    load(process.env.VUE_APP_RECAPTCHA_SITE_KEY as string, {
      autoHideBadge: false
    }).then(recaptcha => {
      recaptchaInstance.value = recaptcha;
      recaptchaInstance.value.hideBadge();
    });
  };

  const getToken = async () => {
    const recaptcha = await load(
      process.env.VUE_APP_RECAPTCHA_SITE_KEY as string
    );
    return await recaptcha.execute('signup');
  };

  return {
    showBadge,
    hideBadge,
    getToken
  };
};

export const useResendConfirmCode = async (): Promise<void> => {
  const router = useRouter();
  const { getToken } = useRecaptcha();
  const { getParams } = useSignupStore();
  getParams.reCaptchaToken = await getToken();

  try {
    // API接続
    await AccountApi.postSignup(getParams);

    toast({
      title: '成功',
      message: '認証コードを再送信しました',
      variant: 'success'
    });
  } catch (e) {
    if (e.type === 'error') {
      if (e.status !== 500) {
        toast({ title: '失敗', message: e.message, variant: 'error' });
      } else {
        // サーバーエラー
        router.push({
          name: ROUTE_NAMES.error
        });
      }
    } else {
      router.push({
        name: ROUTE_NAMES.error
      });
    }
  }
};

export const useAuthCheck = (): void => {
  const router = useRouter();
  const { isAuth } = useSignupData();

  const authCheck = () => {
    if (isAuth) {
      return;
    } else {
      router.push({
        name: ROUTE_NAMES.error,
        params: {
          message:
            'ページの遷移が確認できませんでした。手続きをやり直してください。'
        }
      });
    }
  };
  authCheck();
};
