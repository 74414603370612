import { defineStore } from 'pinia';
import { SignupForm, SignupFormInflowTypeEnum } from '@/api/openapi';

export const useSignupStore = defineStore('signup', {
  state: () => ({
    email: '' as string,
    inflowType: '' as SignupFormInflowTypeEnum,
    advertiseCode: undefined as string | undefined,
    invitationWorkspaceId: undefined as number | undefined,
    invitatorId: undefined as number | undefined,
    reCaptchaToken: '' as string
  }),
  getters: {
    getParams: state => {
      return {
        email: state.email,
        inflowType: state.inflowType,
        advertiseCode: state.advertiseCode,
        invitationWorkspaceId: state.invitationWorkspaceId,
        invitatorId: state.invitatorId,
        reCaptchaToken: state.reCaptchaToken
      };
    }
  },
  actions: {
    setParams(value: SignupForm) {
      this.email = value.email;
      this.inflowType = value.inflowType;
      this.advertiseCode = value.advertiseCode && value.advertiseCode;
      this.invitationWorkspaceId =
        value.invitationWorkspaceId && value.invitationWorkspaceId;
      this.invitatorId = value.invitatorId && value.invitatorId;
    }
  }
});
